<template>
  <div>
    <v-toolbar
        v-if="routes.length > 0 && $route.name !== 'home'"
        dense
        flat
        class="mb-2 mb-md-5"
    >
      <v-row no-gutters>
        <div v-for="(item, i) in routes.length" :key="i" class="d-flex">
          <v-btn
              :to="{ name: routes[item -1].path, query: routes[item -1].query}"
              text
              :disabled="routes[item -1].active ? false : $route.name === routes[item -1].path"
          >
            {{ routes[item - 1].name }}
          </v-btn>
          <v-icon v-if="item !== routes.length" class="px-2">mdi-chevron-right</v-icon>
        </div>
      </v-row>

    </v-toolbar>

  </div>
</template>

<script>
export default {
  name: "DefaultBreadcrumbs",
  computed: {
    routes() {
      let temp = [{"path": "home", "name": "Juptus"}];
      if (this.$store.state.breadcrumbs) {
        return this.$store.state.breadcrumbs
      } else {
        this.$route.matched.map((item, i, {length}) => {
          const crumb = {};
          if (item.name && item.name) {
            crumb.path = item.name;
            crumb.name = this.$store.state.t[this.$route.params.language]['menu'][item.name] || this.$store.getters.page_name;
            temp.push(crumb);
          }
        });
      }
      return temp
    }
  },
  methods: {
    goto(url) {
      this.$router.push({name: url}).catch(() => {
      });
    }
  }
}
</script>

<style scoped lang="scss">
.v-toolbar {
  height: auto !important;
}
::v-deep .v-toolbar__content {
  height: auto !important;
}

.v-btn {
  font-size: 18px !important;
  padding: 0 8px !important;
  margin: 0 -8px !important;
  min-width: 20px !important;
  color: #8C8E9A !important;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    font-size: 14px !important;
  }
}

.theme--light.v-btn.v-btn--disabled {
  color: #2F313D !important;
}

.v-icon {
  color: #8C8E9A !important;
}

.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  opacity: 0 !important;
}
</style>
