<template>
  <v-container>
    <v-card v-if="pageId.data" class="shadow pa-5" :key="'image' + key">
      <template v-if="typeof pageId.data.additional_images === 'object'">
        <DevImageBlock
          :text="t.images"
          :images="pageId.data.additional_images"
          :on-delete="deleteItem"
          contain
        />
        <v-input v-if="pageId.data.additional_images.length < 1" class="error--text font-weight-bold">
          {{ t['required_field'] }}
        </v-input>
      </template>
      <div class="text-center">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="loadImage"
              color="primary"
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ t.add }}</span>
        </v-tooltip>
      </div>
    </v-card>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .JPEG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DevImageBlock from "components/dev/DevImageBlock";

export default {
  name: "construction_partners",
  components: {DevImageBlock},
  data: () => ({
    load_file: '',
    key: 0,
    id: 0,
  }),
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters('pages', ['pageId']),
    ...mapGetters(["file", "apiDomain", "language"]),
  },
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('pages', ['onPage', 'onPageSave']),
    ...mapActions(['uploadFile', 'destroyFile']),
    async loadData() {
      await this.onPage(27).then(() => {
        if (this.pageId.data)
          this.pageId.data.additional_images = JSON.parse(this.pageId.data.additional_images)
        this.key++
      })
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "cover",
      }).then(() => {
        this.pageId.data.additional_images.push(this.file.url)
        this.pageId.data.additional_images = JSON.stringify(this.pageId.data.additional_images)
        this.pageId.data.language_id = 1
        this.onPageSave({id: 27, data: this.pageId.data})
        this.loadData()
      });
    },
    async deleteItem(id) {
      await this.destroyFile(this.pageId.data.additional_images[id])
      this.pageId.data.additional_images.splice(id, 1)
      this.pageId.data.additional_images = JSON.stringify(this.pageId.data.additional_images)
      this.pageId.data.language_id = 1
      await this.onPageSave({id: 27, data: this.pageId.data}).then(() => {
        this.loadData()
      })
    },
  }
}
</script>
