<template>
  <DefaultPageToolbar
      :total="Number(list.meta.total)"
      :last_page="list.meta.last_page"
  >
    <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['messages']"
        :data="list.data"
        :headers="headers"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['name']"
        :on-edit="editItem"
    />
    <v-dialog v-model="modalEdit" width="600">
      <PagesOtherMessagesId
        v-if="modalEdit"
        :data="item"
        :on-close="closeModals"
      />
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
          text="message"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import {mapActions} from "vuex";
import DefaultPageToolbar from "@/components/default/page/DefaultPageToolbar";
import DefaultPageTable from "@/components/default/page/DefaultPageTable";
import PagesOtherMessagesId from "@/components/pages/other/PagesOtherMessagesId";
import DefaultPageAskDelete from "@/components/default/page/DefaultPageAskDelete";

export default {
  name: "messages",
  components: {DefaultPageAskDelete, PagesOtherMessagesId, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {}
    },
    item: [],
    id: 0,
    name: '',
    modalAskDelete: false,
    modalEdit: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["is_viewed", "name"],
          text: this.$store.state.t[this.$route.params.language]['form']['first_name']
        },
        {
          value: ["created_at"],
          text: this.$store.state.t[this.$route.params.language]['fields']['created_at']
        },
        {
          value: ["phone"],
          text: this.$store.state.t[this.$route.params.language]['form']['phone']
        },
        {
          value: ["email"],
          text: this.$store.state.t[this.$route.params.language]['form']['email']
        },
      ]
    }
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("feedback", ["onFeedbackList", "onFeedback", "onDeleteFeedback"]),
    async getList() {
      this.list = {meta: {}}
      await this.onFeedbackList().then(res => {
        this.list = res.data;
        for (let i in this.list.data) {
          this.list.data[i].phone = '+358 ' + this.list.data[i].phone[0] + this.list.data[i].phone[1] + ' ' +
            this.list.data[i].phone[2] + this.list.data[i].phone[3] + this.list.data[i].phone[4] + ' ' +
            this.list.data[i].phone[5] + this.list.data[i].phone[6] + ' ' + this.list.data[i].phone[7] + this.list.data[i].phone[8]
          if (this.list.data[i].is_viewed === 1) {
            this.list.data[i].is_viewed = ''
          }
          else {
            this.list.data[i].is_viewed = [{
              color: '#25c565',
              type: 'icon',
            }]
          }
        }
      })
    },
    closeModals() {
      this.modalAskDelete = false;
      this.modalEdit = false
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      await this.onDeleteFeedback(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    async editItem(id) {
      this.item = []
      await this.onFeedback(id).then(res => {
        this.item = res.data.data
        this.modalEdit = true
      })
    }
  }
}
</script>

<style scoped>

</style>
