<template>
  <DefaultPageModal
    :text="data.id ? t['comment'] : t['add_comment']"
    :on-close="onClose"
  >
    <v-form ref="form" lazy-validation :key="'form' + key">
      <div class="d-flex align-center justify-end gap-5">
        <v-avatar color="primary" size="80">
          <v-img v-if="data.image && data.image.length" :src="apiDomain + '/' + data.image"/>
          <span v-else class="white--text font-weight-bold text-h4 mt-1">{{data.title[0]}}</span>
        </v-avatar>
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="loadImage(false)"
              color="primary"
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="data.image" class="min">mdi-refresh</v-icon>
              <v-icon v-else class="min">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ data.image ? t.change : t.add }}</span>
        </v-tooltip>
      </div>
      <DevInput
        :text="t['first_name']"
        v-model="data.title"
        :rules="[v => !!v || t['required_field']]"
        class="flex-grow-1"
      />
      <DevInput
        text-area
        :text="t['comment']"
        v-model="data.subtext.text"
      />
      <div class="d-flex align-center gap-5">
        <DevImageBlock
          :text="t.image"
          :images="data.subtext.image"
          :on-delete="deleteImage"
          contain
        />
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="loadImage(true)"
              color="primary"
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="data.subtext.image.length" class="min">mdi-refresh</v-icon>
              <v-icon v-else class="min">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ data.subtext.image.length ? t.change : t.add }}</span>
        </v-tooltip>
      </div>
    </v-form>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .JPEG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
    <v-btn slot="btn" @click="saveData" class="primary">
      {{ data.id ? t['save'] : t['create'] }}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "components/default/page/DefaultPageModal.vue";
import {mapActions, mapGetters} from "vuex";
import DevInput from "components/dev/DevInput.vue";
import DevImageBlock from "components/dev/DevImageBlock.vue";

export default {
  name: "CommentModal",
  components: {DevImageBlock, DevInput, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {},
    onItem: {},
    text: {
      type: Object,
      default: () => {
        return null
      }
    },
    category: {
      type: [Number, String],
      default: 10,
    }
  },
  data: () => ({
    load_file: '',
    data: {
      title: '',
      image: '',
      subtext: {image: [], text: ''},
      description: [],
    },
    key: 0,
    error: false,
    deleted_files: [],
    new_files: [],
    click_save: false,
    temp: {},
    description: false,
  }),
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form']
      )
    },
    ...mapGetters("article", ["article"]),
    ...mapGetters(["file", "apiDomain"]),
  },
  watch: {
    article() {
      this.data.id = this.article.data.id
      this.data.title = this.article.data.title
      this.data.subtext = JSON.parse(this.article.data.subtext)
      this.data.subtext.image = this.data.subtext.image === '' ? [] : [this.data.subtext.image]
      this.data.image = this.article.data.image === '-' ? '' : this.article.data.image
      this.data.description = this.article.data.description === '-' ? [] : [this.article.data.description]
      this.key++
    },
    load_file() {
      this.submitFile();
    },
  },
  created() {
    this.getItem()
  },
  beforeDestroy() {
    this.closeItem()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    closeItem() {
      console.log('закрываем окно')
      if (this.click_save) {
        console.log('нажата кнопка сохранения')
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        console.log('нажата кнопка выхода')
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_files = []
      this.new_files = []
      this.click_save = false
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.click_save = true
        let temp = {
          title: this.data.title,
          subtext: JSON.stringify(this.data.subtext),
          image: this.data.image ? this.data.image : '-',
          description: '-',
          language_id: 1,
          alt: '-',
          article_author_id: 4,
          article_category_id: this.category,
          keywords: '-',
          seo_title: this.data.title,
          text: '-',
          seo_image: '-'
        }
        this.onSave(temp)
      }
    },
    getItem() {
      if (this.onItem) {
        this.onItem()
      }
    },
    async deleteImage(id) {
      this.deleted_files.push(this.data.subtext.image[0])
      this.data.subtext.image.splice(id, 1)
    },
    loadImage(description) {
      this.description = description
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "cover",
      }).then(() => {
        this.new_files.push(this.file.url)
        if (this.description) this.data.subtext.image[0] = this.file.url
        else this.data.image = this.file.url
        this.description = false
        this.key++
      });
    },
  },
}
</script>

<style scoped lang="scss">
.gap-5 {
  gap: 20px;
}
</style>