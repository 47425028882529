<template>
  <DefaultPageMenu
    name="construction"
    :routes="Array('construction_main', 'construction_services', 'construction_partners','construction_comments', 'construction_seo')"
  />
</template>

<script>
import DefaultPageMenu from "components/default/page/DefaultPageMenu";
export default {
  name: "construction",
  components: {DefaultPageMenu}
}
</script>

<style scoped>

</style>
