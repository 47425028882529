import axios from "axios";

const state = {
  interfaceData: [],
  text: {}
};

const getters = {
  interfaceData(state) {
    return state.interfaceData;
  },
  text(state) {
    return state.text
  }
};

const mutations = {
  setInterfaceData: (state, item) => {
    state.interfaceData = item;
  },
  setText: (state, data) => {
    state.text = data;
  }
};

const actions = {
  onInterfaceData({commit, dispatch}, {id}) {
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/interface?language_id=1&interface_group_id=" + id;
      axios
        .get(url)
        .then(response => {
          commit("setInterfaceData", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onInterfaceDataSave({commit, dispatch}, data) {
    commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/interface/update";
      axios
        .put(url, data)
        .then(response => {
          commit("SetLoadingAnimation", false, {root: true});
          resolve(response);
          dispatch("notification/onNotification", {IsError: false}, {root: true});
        })
        .catch(error => {
          commit("SetLoadingAnimation", false, {root: true});
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onText({commit, dispatch}, {id}) {
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/text?language_id=1&interface_group_id=" + id;
      axios
        .get(url)
        .then(response => {
          commit("setText", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onTextSave({commit, dispatch}, data) {
    commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/text/update";
      axios
        .put(url, data)
        .then(response => {
          commit("SetLoadingAnimation", false, {root: true});
          resolve(response);
          dispatch("notification/onNotification", {IsError: false}, {root: true});
        })
        .catch(error => {
          commit("SetLoadingAnimation", false, {root: true});
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
