<template>
  <DefaultPageModal
    :text="data.id ? t['service'] : t['add_service']"
    :on-close="onClose"
  >
    <v-form ref="form" lazy-validation :key="'form' + key">
      <DevInput
        :text="t['title']"
        v-model="data.title"
        :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
        v-if="category !== 15"
        v-model="data.subtext"
        :text="t['subtext']"
        class="mt-5"
      />
      <DevInput
        text-area
        :text="t['description']"
        v-model="data.text"
        :rules="[v => !!v || t['required_field']]"
      />
      <v-card class="border pa-5" :key="key" v-if="typeof data.image === 'object'">
        <DevImageBlock
          :text="t.images"
          :images="data.image"
          :on-delete="deleteImage"
          contain
          :description="data.image_description"
          ref="img_block"
        />
        <v-input v-if="error && data.image.length < 1" class="error--text font-weight-bold">
          {{ t['required_field'] }}
        </v-input>
        <div class="text-center">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="loadImage"
                color="primary"
                fab
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ t.add }}</span>
          </v-tooltip>
        </div>
      </v-card>
      <v-card class="border pa-5 mt-5">
        <v-input>{{ 'SEO' }}</v-input>
        <DevInput
          v-model="data.seo_title"
          :text="t.title"
          :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
          v-model="data.keywords"
          text-area
          :text="t.keywords"
          :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
          v-model="data.description"
          text-area
          :text="t.description"
          :rules="[v => !!v || t['required_field']]"
        />
      </v-card>
    </v-form>
    <v-btn v-if="typeof data.image === 'object'" slot="btn" @click="saveData" class="primary"
           :disabled="data.image.length < 1">
      {{ data.id ? t['save'] : t['create'] }}
    </v-btn>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .JPEG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "components/default/page/DefaultPageModal";
import {mapActions, mapGetters} from "vuex";
import DevImageBlock from "components/dev/DevImageBlock";
import DevInput from "components/dev/DevInput";

export default {
  name: "ServiceModal",
  components: {DevInput, DevImageBlock, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {},
    onItem: {},
    category: {
      type: [Number, String],
      default: 15
    },
    text: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: () => ({
    load_file: '',
    data: {
      image: []
    },
    key: 0,
    error: false,
    deleted_files: [],
    new_files: [],
    click_save: false,
    temp: {}
  }),
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form']
      )
    },
    ...mapGetters(["language"]),
    ...mapGetters("service", ["service"]),
    ...mapGetters(["file", "apiDomain"]),
  },
  watch: {
    service() {
      this.data = this.service.data
      this.data.image = JSON.parse(this.data.image)
      if (this.data.description === '~') this.data.description = null
      if (this.data.text === '~') this.data.text = null
      if (this.data.subtext === '~') this.data.subtext = null
      if (this.data.id && this.text) {
        this.data.image_description = this.text[this.data.id] || []
      }
      console.log(this.data)
      this.key++
    },
    load_file() {
      this.submitFile();
    },
  },
  created() {
    this.getItem()
  },
  beforeDestroy() {
    this.closeItem()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    closeItem() {
      console.log('закрываем окно')
      if (this.click_save) {
        console.log('нажата кнопка сохранения')
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        console.log('нажата кнопка выхода')
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_files = []
      this.new_files = []
      this.click_save = false
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.temp = {}
        this.click_save = true
        this.temp.language_id = 1
        this.temp.text = this.data.text || '~'
        this.temp.title = this.data.title
        this.temp.alt = this.data.title
        this.temp.description = this.data.description || '~'
        this.temp.keywords = this.data.keywords || this.data.title
        this.temp.seo_title = this.data.seo_title || this.data.title
        this.temp.subtext = this.data.subtext || '~'
        this.temp.url = this.data.title
        this.temp.seo_image = '/uploads/isCX4cjYNpJwDCltzj3etmFDd2eDWB08k7VqJ8Rr.png'
        this.temp.icon = 'uploads/k59u3pBFmPwubvEMxDjl2aghVhNdkzlQ3VtyIucT.svg'
        this.temp.docs = "null"
        this.temp.is_delivery_required = false
        this.temp.is_no_price = false
        this.temp.price = 1
        this.temp.price2 = 1
        this.temp.price3 = 1
        this.temp.image = JSON.stringify(this.data.image)
        this.temp.service_category_id = this.category
        this.onSave(this.temp, this.$refs.img_block.description)
      }
    },
    getItem() {
      if (this.onItem) {
        this.data.image_description = []
        this.onItem()
      }
    },
    async deleteImage(id) {
      this.deleted_files.push(this.data.image[id])
      this.data.image.splice(id, 1)
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "cover",
      }).then(() => {
        this.new_files.push(this.file.url)
        this.data.image.push(this.file.url)
        this.key++
      });
    },
  }
}
</script>

<style scoped>

</style>
