<template>
  <v-container class="rel">
    <v-form v-if="interfaceData.data && list['name1']" lazy-validation ref="form">
      <v-card class="shadow pa-5" :key="'names' + key">
        <div class="grid_1">
          <DevInput
            v-model="list['name1'].value"
            :rules="[v => !!v || t['required_field']]"
            :text="t.first_name"
          />
          <div v-if="$vuetify.breakpoint.smAndUp">{{ '/' }}</div>
          <DevInput
            v-model="list['position1'].value"
            :rules="[v => !!v || t['required_field']]"
            :text="t.position"
          />
          <DevInput
            v-model="list['name2'].value"
            :rules="[v => !!v || t['required_field']]"
            :text="t.first_name"
          />
          <div v-if="$vuetify.breakpoint.smAndUp">{{ '/' }}</div>
          <DevInput
            v-model="list['position2'].value"
            :rules="[v => !!v || t['required_field']]"
            :text="t.position"
          />
          <DevInput
            v-model="list['name3'].value"
            :rules="[v => !!v || t['required_field']]"
            :text="t.first_name"
          />
          <div v-if="$vuetify.breakpoint.smAndUp">{{ '/' }}</div>
          <DevInput
            v-model="list['position3'].value"
            :rules="[v => !!v || t['required_field']]"
            :text="t.position"
          />
        </div>
      </v-card>
      <v-card class="shadow pa-5 mt-5" :key="'phones' + key">
        <div class="grid_1">
          <DevInput
            v-model="list['phone1'].value"
            phone
            :text="t.phone"
          />
        </div>
        <div class="grid_1">
          <DevInput
            v-model="list['phone2'].value"
            phone
            :text="t.phone"
          />
        </div>
      </v-card>
      <v-card class="shadow pa-5 mt-5" :key="'emails' + key">
        <div class="grid_1">
          <DevInput
            v-model="list['email1'].value"
            :text="t.email"
            :rules="[v => !!v || t['required_field'], v => /.+@.+\..+/.test(v) || t['bad_email']]"
          />
        </div>
        <div class="grid_1">
          <DevInput
            v-model="list['email2'].value"
            :text="t.email"
            :rules="[v => !!v || t['required_field'], v => /.+@.+\..+/.test(v) || t['bad_email']]"
          />
        </div>
      </v-card>
      <v-card class="shadow pa-5 mt-5" :key="'address' + key">
        <div class="grid_1">
          <DevInput
            v-model="list['location'].value"
            :text="t.address"
            :rules="[v => !!v || t['required_field']]"
          />
          <div v-if="$vuetify.breakpoint.smAndUp">{{ '/' }}</div>
          <DevInput
            v-model="list['map'].value"
            :text="t.url"
            :rules="[v => !!v || t['required_field']]"
          />
        </div>
        <div class="grid_1">
          <DevInput
            v-model="list['id'].value"
            :text="t.ytunnus"
            :rules="[v => !!v || t['required_field']]"
          />
        </div>
      </v-card>
      <v-card class="shadow pa-5 mt-5" :key="'work' + key">
        <div class="grid_1">
          <DevInput
            v-model="list['ma-pe'].value"
            text="Ma-Pe"
            :rules="[v => !!v || t['required_field']]"
          />
        </div>
        <div class="grid_1">
          <DevInput
            v-model="list['la-su'].value"
            text="La-Su"
            :rules="[v => !!v || t['required_field']]"
          />
        </div>
      </v-card>
    </v-form>
    <div class="fixed_btn">
      <v-btn color="primary" fab @click="onSave">
        <v-icon class="min">mdi-upload</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";

export default {
  name: "contacts",
  components: {DevInput},
  data: () => ({
    key: 0,
    list: {}
  }),
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters('interfaceData', ['interfaceData']),
    ...mapGetters('pages', ['pageId']),
    ...mapGetters(["file", "apiDomain", "language"]),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('interfaceData', ['onInterfaceData', 'onInterfaceDataSave']),
    async loadData() {
      await this.onInterfaceData({id: 48}).then(() => {
        if (this.interfaceData.data) {
          this.list = {}
          let temp = Object.assign(this.interfaceData.data)
          temp.forEach((cur) => {
            this.list[cur.name] = cur
          })
        }
        this.key++
      })
    },
    async onSave() {
      let data = Object.values(this.list)
      data.forEach((cur) => {
        cur.interface_entity_id = cur.id
      })
      await this.onInterfaceDataSave({
        language_id: 1,
        translations: data
      })
    },
  }
}
</script>

<style scoped lang="scss">
.grid_1 {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-column-gap: 10px;
  
  @media #{map-get($display-breakpoints, 'xs-only')} {
    grid-template-columns: 1fr;
  }
}
</style>
