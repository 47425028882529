import axios from "axios";

const state = {
    serviceCategoryList: [],
    serviceCategory: [],
    serviceList: [],
    service: [],
    serviceUnit: []
};

const getters = {
    serviceCategoryList(state) {
        return state.serviceCategoryList;
    },
    serviceCategory(state) {
        return state.serviceCategory;
    },
    serviceList(state) {
        return state.serviceList;
    },
    service(state) {
        return state.service;
    },
    serviceUnit(state) {
        return state.serviceUnit;
    }
};

const mutations = {
    setServiceCategoryList: (state, item) => {
        state.serviceCategoryList = item;
    },
    setServiceCategory: (state, item) => {
        state.serviceCategory = item;
    },
    setServiceList: (state, item) => {
        state.serviceList = item;
    },
    setService: (state, item) => {
        state.service = item;
    },
    setServiceUnit: (state, item) => {
        state.serviceUnit = item;
    }
};

const actions = {
    onServiceCategoryList({commit, dispatch}, {title, number}) {
        //commit("SetLoadingAnimation", true, {root: true});
        commit("setServiceCategoryList", []);
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/serviceCategory?" +
                "number=" +
                (number ? number : this.state.number) +
                "&page=" +
                this.state.page + "&method=desc&field=id";
            if (title) {
                url = url + "&title=" + title;
            }
            axios
                .get(url)
                .then(response => {
                    commit("setServiceCategoryList", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceCategory({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/serviceCategory/" +
                id +
                "/edit?language_id=" +
                this.state.language;
            axios
                .get(url)
                .then(response => {
                    commit("setServiceCategory", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceCategoryCreate({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/serviceCategory"
            axios.post(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceCategoryDelete ({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/serviceCategory/" + id
            axios.delete(url)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceCategorySave ({commit, dispatch}, {id, data}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/serviceCategory/" + id
            axios.put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceCategoryFieldPut ({commit, dispatch}, {id, data}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/serviceCategory/" + id + "/field"
            axios.put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceList({commit, dispatch}, {category, page, number}) {
        //commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/service?" +
                "number=" +
                (number ? number : this.state.number) +
                "&page=" +
                (page ? page : this.state.page + "&method=asc&field=id");
            if (category) {
                url = url + "&service_category_id=" + category
            }
            axios
                .get(url)
                .then(response => {
                    commit("setServiceList", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onService({commit, dispatch}, {id}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/service/" +
                id +
                "/edit?language_id=" +
                this.state.language;
            axios
                .get(url)
                .then(response => {
                    commit("setService", response.data);
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceSave({commit, dispatch}, {id, data}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/service/" +
                id;
            axios
                .put(url, data)
                .then(response => {
                    resolve(response);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    reject(error);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceCreate ({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/service"
            axios.post(url, data)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceDelete ({commit, dispatch}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/service/" + id
            axios.delete(url)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceFieldPut ({commit, dispatch}, {id, data}) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain + "/api/service/" + id + "/field"
            axios.put(url, data)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },
    onServiceUnit({commit, dispatch}, {service, id, number}) {
        //commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url =
                this.state.apiDomain +
                "/api/admin/service-unit/grid?limit=" +
                (number ? number : this.state.number) + "&page=" + this.state.page + "&order[]=id:desc&";
            if (service) {
                url = url + "service_id=" + service;
            }
            if (id) {
                url = url + "&filter[]=id:" + id;
            }
            axios
                .get(url)
                .then(response => {
                    commit("setServiceUnit", response.data);
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        });
    },

    onServiceUnitDelete({commit}, id) {
        commit("SetLoadingAnimation", true, {root: true});
        let url = this.state.apiDomain + "/api/admin/service-unit/" + id;
        axios
            .delete(url)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    },
    // eslint-disable-next-line no-unused-vars
    onServiceUnitPut({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/service-unit";
            axios
                .put(url, data)
                .then(response => {
                    commit("SetLoadingAnimation", false, {root: true});
                    resolve(response);
                    dispatch("notification/onNotification", {IsError: false}, {root: true});

                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        })
    },
    // eslint-disable-next-line no-unused-vars
    onServiceUnitPost({commit, dispatch}, data) {
        commit("SetLoadingAnimation", true, {root: true});
        return new Promise((resolve, reject) => {
            let url = this.state.apiDomain + "/api/admin/service-unit";
            axios
                .post(url, data)
                .then(response => {
                    commit("setServiceUnit", response.data);
                    commit("SetLoadingAnimation", false, {root: true});
                    dispatch("notification/onNotification", {IsError: false}, {root: true});

                    resolve(response);
                })
                .catch(error => {
                    commit("SetLoadingAnimation", false, {root: true});
                    reject(error);
                    dispatch("notification/onNotification", {IsError: true}, {root: true});
                });
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
