<template>
  <v-container class="rel">
    <v-card class="shadow pa-5">
      <v-form v-if="pageId.data" lazy-validation ref="form" :key="key">
        <DevInput
          v-model="pageId.data.seo_title"
          :text="t.title"
          :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
          v-model="pageId.data.keywords"
          text-area
          :text="t.keywords"
          :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
          v-model="pageId.data.description"
          text-area
          :text="t.description"
          :rules="[v => !!v || t['required_field']]"
        />
        <v-card class="d-flex justify-space-around align-center border pa-5" width="280">
          <DevImageBlock
            :text="t.image"
            :images="Array(pageId.data.image)"
          />
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="loadImage"
                color="primary"
                fab
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-autorenew</v-icon>
              </v-btn>
            </template>
            <span>{{ t.change }}</span>
          </v-tooltip>
        </v-card>
      </v-form>
    </v-card>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .JPEG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
    <div class="fixed_btn">
      <v-btn color="primary" fab @click="savePage">
        <v-icon class="min">mdi-upload</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import DevInput from "components/dev/DevInput";
import {mapActions, mapGetters} from "vuex";
import DevImageBtn from "components/dev/DevImageBtn";
import DevImageBlock from "components/dev/DevImageBlock";

export default {
  name: "cleaning_seo",
  components: {DevImageBlock, DevImageBtn, DevInput},
  data: () => ({
    load_file: '',
    key: 0,
  }),
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters('pages', ['pageId']),
    ...mapGetters(["file", "apiDomain", "language"]),
  },
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  mounted() {
    this.loadPage()
  },
  methods: {
    ...mapActions('pages', ['onPage', 'onPageSave']),
    ...mapActions(['uploadFile', 'destroyFile']),
    async loadPage() {
      await this.onPage(26).then(() => {
        this.key++
      })
    },
    savePage() {
      if (this.$refs.form.validate()) {
        this.pageId.data.language_id = 1
        this.onPageSave({id: 26, data: this.pageId.data})
      }
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "cover",
      }).then(() => {
        this.destroyFile(this.pageId.data.image)
        this.pageId.data.image = this.file.url
        this.savePage()
        this.key++
      });
    }
  }
}
</script>
