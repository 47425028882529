<template>
  <div>
    <div class="my-table">
      <v-sheet width="100%" color="white" class="grid px-6">
        <div class="hidden-lg-and-down">
          <div class="d-flex">
            <div v-if="orderBy.value" class="order">
              <v-btn text class="head-table grey--text text--darken-1 ml-n7">
                {{ order.text }}
              </v-btn>
            </div>
            <v-card
              v-for="(index, i) in headers.length"
              :key="i"
              width="100%"
              flat
              color="transparent"
            >
              <div class="head-table grey--text text--darken-1">
                <!--              <v-icon class="mr-1">mdi-menu-swap</v-icon>-->
                {{ headers[index - 1].text }}
              </div>
            </v-card>
          </div>
        </div>
        <div class="hidden-xl-only">
          <div class="d-flex">
            <v-card
              width="100%"
              flat
            >
              <v-btn text class="head-table grey--text text--darken-1">
                <!--              <v-icon class="mr-1">mdi-menu-swap</v-icon>-->
                {{ title }}
              </v-btn>
            </v-card>
          </div>
        </div>
        <div>
          <v-btn v-if="onActive" disabled text width="120"></v-btn>
          <v-btn v-if="onEdit" disabled text width="58"></v-btn>
          <v-btn v-if="onDelete" disabled text width="58"></v-btn>
        </div>
      </v-sheet>
      <div v-if="data">
        <v-list v-if="data[0]">
          <v-divider/>
          <div v-for="(item, i) in data" :key="i">
            <v-list-item class="justify-space-between my_list" link
                         @click="onClick ? onClick(item.id) : onEdit(item[editField])">
              <div v-if="orderBy.value" class="order">
                <div class="text hidden-xl-only">
                  {{ orderBy.text }}
                </div>
                {{ item[orderBy.value] }}
              </div>
              <div class="card_lg">
                <v-card
                  v-for="(index, ii) in headers.length"
                  :key="ii"
                  width="100%"
                  color="transparent"
                  flat
                  class="d-flex align-center ml-n2 px-1"
                >
                  <div>
                    <div v-if="!headers[index - 1].text_position" class="text hidden-xl-only">
                      {{ headers[index - 1].text }}
                    </div>
                    <div class="d-flex align-center temp">
                      <div v-for="(head, h) in headers[index - 1].value" :key="h">
                        <div v-if="head === headers[index - 1].text_position" class="text hidden-xl-only">
                          {{ headers[index - 1].text }}
                        </div>
                        <div v-if="typeof item[head] === 'string'" class="d-flex">
                          <span v-if="item[head].length < 40" class="mr-1">{{ item[head] }}</span>
                          <span v-else>{{ item[head].slice(0, 35) }}...</span>
                        </div>
                        <div v-else-if="typeof item[head] === 'number'" class="d-flex">
                          <span> {{ item[head] }} </span>
                        </div>
                        <div v-else-if="item[head]" class="d-flex">
                          <div v-for="(ln, l) in item[head].length" :key="l">
                            <v-btn v-if="item[head][ln-1].type === 'btn'"
                                   :color="item[head][ln-1]['color']"
                                   :outlined="item[head][ln-1]['marker']"
                                   retain-focus-on-click
                                   depressed
                                   :icon="item[head][ln-1]['icon']"
                                   class="btn text-none white--text"
                                   :style="item[head][ln-1]['text'] ? 'color: ' +  item[head][ln-1]['text'] + ' !important' : ''">
                              <v-icon v-if="item[head][ln-1]['marker'] || item[head][ln-1]['icon']">mdi-circle-medium
                              </v-icon>
                              {{ item[head][ln - 1]['value'] }}
                            </v-btn>
                            <v-icon v-else-if="item[head][ln-1].type === 'icon'" class="mr-2"
                                    color="#25c565">mdi-circle-medium
                            </v-icon>
                            <v-img
                              v-else-if="item[head][ln-1].type === 'image' && item[head][ln-1].src"
                              :src="item[head][ln-1].src"
                              width="90"
                              height="90"
                              class="mr-2 my-2"
                              contain
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="secondary"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <v-card
                              v-else
                              tile
                              flat
                              height="90"
                              width="90"
                              class="d-flex align-center justify-center mr-2 my-2"
                              color="grey"
                            >
                              <v-icon size="40" class="white--text min">mdi-image-off</v-icon>
                            </v-card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </v-card>
              </div>
              <div class="d-flex flex-column flex-sm-row">
                <div v-if="onActive">
                  <v-switch v-model="item[activeField]" inset @click.stop="onActive(item.id, item[activeField])"
                            hide-details class="mt-3"/>
                </div>
                <div v-if="onEdit">
                  <v-btn @click.stop="edit(item)" fab text>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
                <div v-if="onDelete">
                  <v-btn @click.stop="del(item)" fab text>
                    <v-icon>mdi-close-thick</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-list-item>
            <v-divider/>
          </div>
        </v-list>
        <v-card v-else flat>
          <v-divider/>
          <v-card-title class="grey--text my-16 justify-center">
            {{ t.no_data }}
          </v-card-title>
        </v-card>
      </div>
      <div v-else>
        <v-list v-for="load in Number(number)" :key="load">
          <v-divider/>
          <v-list-item class="justify-space-between my_list">
            <div v-if="orderBy.value" class="order">
              <div class="text hidden-xl-only">
                {{ orderBy.text }}
              </div>
              {{ item[orderBy.value] }}
            </div>
            <div class="card_lg">
              <v-card
                v-for="(index, ii) in headers.length"
                :key="ii"
                width="100%"
                color="transparent"
                flat
                class="d-flex align-center"
              >
                <div>
                  <div v-if="!headers[index - 1].text_position" class="text hidden-xl-only">
                    {{ headers[index - 1].text }}
                  </div>
                  <div class="d-flex align-center temp">
                    <div v-for="(head, h) in headers[index - 1].value" :key="h">
                      <div v-if="head === headers[index - 1].text_position" class="text hidden-xl-only">
                        {{ headers[index - 1].text }}
                      </div>
                      <v-skeleton-loader
                        v-if="head === 'image' || head === 'icon'"
                        type="image"
                        width="90"
                        height="90"
                        class="my-2 mr-2"
                      />
                      <v-skeleton-loader
                        v-else
                        type="text"
                        width="120"
                      />
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div class="d-flex flex-column flex-sm-row">
              <div v-if="onActive">
                <v-switch inset loading disabled v-model="temp" hide-details class="mt-3"/>
              </div>
              <div v-if="onEdit">
                <v-btn fab text disabled>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <div v-if="onDelete">
                <v-btn fab text disabled>
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "DefaultPageTable",
  props: {
    data: {
      type: Array,
      default: () => {
        return null;
      }
    },
    headers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    title: {
      type: String,
      default: ""
    },
    orderBy: {
      type: Object,
      default: () => {
        return {};
      }
    },
    askDeleteFields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    editField: {
      type: String,
      default: 'id'
    },
    activeField: {
      type: String,
      default: "is_active"
    },
    onEdit: {},
    onClick: {},
    onDelete: {},
    onActive: {}
  },
  data: () => ({
    temp: true
  }),
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['notification']
      )
    },
    ...mapGetters(['number'])
  },
  methods: {
    del(item) {
      let name = '';
      for (let i in this.askDeleteFields) {
        name = name + item[this.askDeleteFields[i]] + ' ';
      }
      this.onDelete(item.id, name, this.data.indexOf(item))
    },
    edit(item) {
      this.onEdit(item[this.editField], this.data.indexOf(item))
    }
  }
}
</script>

<style scoped lang="scss">

span {
  word-break: break-word !important;
}

.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  align-items: center;
  
  @media #{map-get($display-breakpoints, 'xl-only')} {
    grid-gap: 4px;
  }
  
  & .head-table {
    font-size: 17px !important;
    font-weight: 500 !important;
    padding: 0 !important;
  }
}

.theme--light.v-list-item {
  height: auto !important;
  
  &:hover {
    background-color: var(--v-grey-lighten2) !important;
  }
}

.v-list-item {
  height: auto !important;
  padding: 0 24px !important;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 8px !important;
  }
  
  &:hover {
    background-color: var(--v-grey-lighten2) !important;
    
    ::v-deep *:not(.v-btn__content):not(.v-btn) {
      color: var(--v-primary-darken1) !important;
    }
    
    ::v-deep .theme--light.v-input--switch .v-input--switch__track {
      color: darkgrey !important;
    }
  }
  
  .order {
    width: 50px;
  }
  
  .card_lg {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4px;
    margin: 4px !important;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      grid-template-columns: 1fr;
      //margin: 4px !important;
    }
    @media #{map-get($display-breakpoints, 'xl-only')} {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-gap: 0;
      margin: 0 !important;
    }
  }
  
  .card_sm {
    width: calc(100% - 70px) !important;
  }
  
  .btn {
    border-radius: 8px !important;
    height: 34px !important;
    
    &:hover {
      cursor: default !important;
    }
    
    &:before {
      opacity: 0.15 !important;
    }
  }
  
  .temp {
    @media #{map-get($display-breakpoints, 'xl-only')} {
      height: 100%;
    }
  }
}

::v-deep .v-list-item--link:before {
  background-color: var(--v-grey-lighten2) !important;
}

.v-list-item--link:before {
  background-color: var(--v-grey-lighten2) !important;
}

.v-divider {
  border-color: var(--v-grey-lighten1) !important;
}

.v-btn.v-btn--disabled {
  color: inherit !important;
}

.v-input {
  margin-right: 70px !important;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-right: 0 !important;
  }
}

.text {
  font-size: 10px;
  font-weight: 300;
  color: #8C8E9A;
}
</style>
