<template>
  <DefaultPageToolbar
    :total="Number(list.meta.total)"
    :last_page="list.meta.last_page"
    :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_comment']"
    :on-create="openNewItem"
  >
    <DefaultPageTable
      :title="$store.state.t[this.$route.params.language]['menu']['construction_comments']"
      :data="list.data"
      :headers="headers"
      :on-edit="editItem"
      :on-delete="askDeleteItem"
      :on-active="activeItem"
      :ask-delete-fields="['name']"
    />
    <v-dialog v-model="modalNewItem" width="600" style="z-index: 1000">
      <CommentModal v-if="modalNewItem" :on-save="createItem" :on-close="closeModals"/>
    </v-dialog>
    <v-dialog v-model="modalEditItem" width="600" style="z-index: 1000">
      <CommentModal v-if="modalEditItem" :on-item="loadItem" :on-save="saveItem" :text="text" :on-close="closeModals"/>
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600" style="z-index: 1000">
      <DefaultPageAskDelete
        text="comment"
        :name="name"
        :on-close="closeModals"
        :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar.vue";
import DefaultPageTable from "components/default/page/DefaultPageTable.vue";
import {mapActions, mapGetters} from "vuex";
import CommentModal from "components/pages/comments/CommentModal.vue";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete.vue";

export default {
  name: "construction_comments",
  components: {DefaultPageAskDelete, CommentModal, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {}
    },
    item: {},
    id: 0,
    name: '',
    modalAskDelete: false,
    modalNewItem: false,
    modalEditItem: false,
    text: {},
  }),
  computed: {
    headers() {
      return [
        {
          value: ["name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['client'],
        }
      ]
    },
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters(["apiDomain"]),
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions('article', ['onArticleList', 'onArticleCreate', 'onArticleSaveField', 'onArticle', 'onArticleSave', 'onArticleDelete']),
    async getList() {
      this.list = {meta: {}}
      await this.onArticleList({category: 10}).then(res => {
        this.list = res.data;
      })
    },
    closeModals() {
      this.modalAskDelete = false;
      this.modalNewItem = false;
      this.modalEditItem = false
      this.is_units = false
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    editItem(id) {
      this.id = id
      this.modalEditItem = true
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async deleteItem() {
      await this.onArticleDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    activeItem(id, state) {
      this.onArticleSaveField({id: id, data: {field: "is_active", value: state}})
    },
    async createItem(data) {
      await this.onArticleCreate(data).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    loadItem() {
      this.onArticle(this.id)
    },
    async saveItem(data) {
      await this.onArticleSave({id: this.id, data: data}).then(() => {
        this.getList();
        this.closeModals()
      })
    }
  }
}
</script>